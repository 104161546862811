<script setup lang="ts">
import { FastShippingProps } from "./FastShipping.props"

withDefaults(defineProps<FastShippingProps>(), {
  theme: "yellow"
})

const themeMap = {
  yellow: "bg-yellow-10",
  green: "bg-green-10"
}
</script>

<template>
  <div
    class="banner-delivery-fast_shipping rounded-lg px-4 py-4"
    :class="themeMap[theme]"
  >
    <div
      class="flex flex-col md:flex-row md:items-center"
      :class="isTiny ? 'mb-2' : 'mb-4'"
    >
      <UtilsIcon
        name="BannerDelivery.svg"
        v-if="theme === 'yellow'"
        class="mb-2 mr-2 h-auto"
        :class="[isTiny ? 'w-6' : 'w-10']"
      />

      <UtilsIcon
        name="BannerDeliveryTime.svg"
        v-else
        class="mb-2 mr-2 h-auto"
        :class="[isTiny ? 'w-6' : 'w-10']"
      />

      <h5 v-if="title" class="pig-bold md:dolphin-bold text-green-main">
        {{ title }}
      </h5>
    </div>

    <div>
      <UtilsMarkdown
        v-if="text"
        class="beaver markdown__link-custom-underlined"
        :class="{ 'markdown__strong-green-main': isGreenBold }"
        :content="text"
      />
    </div>
  </div>
</template>
